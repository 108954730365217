/* eslint-disable react/jsx-indent */
import React, { FC } from 'react'
import _ from 'lodash'
import './Highlight.scss'

interface HighlightProps {
  title: string
  description?: string
  image: string
  isLast: boolean
  maxHeight?: string
}

const Highlight: FC<HighlightProps> = ({
  title,
  description = '',
  image = '',
  isLast = false,
  maxHeight = ''
}) => {
  function addImage (): any {
    const highlightImage = image

    if (!_.isNil(highlightImage)) {
      if (highlightImage.length > 0) {
        return (
          <img
            src={highlightImage}
            className='highlight-header__img'
            alt='Hihglight'
            style={{ maxHeight }}
          />
        )
      }
    }

    return true
  }
  return (
    <div
      className={`highlight-header ${isLast ? 'highlight-header__last' : ''}`}
    >
      <div className='highlight-header__base'>
        <div className='highlight-header__imgholder'>{addImage()}</div>
        <div className='highlight-header__text'>
          <div className='highlight-header__title'>{title}</div>
          <div className='highlight-header__description'>{description}</div>
        </div>
      </div>
    </div>
  )
}

export default Highlight
