import React, { FC, useEffect, useRef, useState } from 'react'
import * as moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import { useTracking } from 'react-tracking'

import { RedemptionType } from '../../../offer/redemptionType'
import campaignIco from '../../../../assets/images/campaign/campaign-ico.png'
import deductIcon from '../../../../assets/images/deductIcon.png'
import offerIco from '../../../../assets/images/offer/gift.png'
import { TransactionType } from '../../transactionType'
import RedemptionWithCode from '../../../offer/OfferList/RedemptionResultDrawer/RedemptionWithCode/RedemptionWithCode'
import RedemptionWithTicket from '../../../offer/OfferList/RedemptionResultDrawer/RedemptionWithTicket/RedemptionWithTicket'
import { selectOfferByTransaction, selectUser } from '../../selectors'
import { isLatter } from '../../../core/utils/time'
import { selectStartAppUTC } from '../../../config/selectors'
import BaseDrawer from '../../../ui/BaseDrawer/BaseDrawer'
import { RedemptionCenterCondition } from '../../../offer/redemptionCenterCondition'
import OfferTermsDrawer from '../../../offer/OfferList/OfferTermsDrawer/OfferTermsDrawer'
import { DEDUCTION_TYPES } from '../../userConstants'

import './TransactionHistoryItem.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../../core/utils/css-selectors'
import { AnalyticsCategory } from '../../../core/analytics/analyticsCategory'

interface TransactionHistoryListProps {
  transaction: Transaction
}

const REFUNDED = '(refunded)'
const INACTIVITY = '(inactivity)'
const SUSPENSION = '(Suspended)'
const EXPIRED = '(Expired)'
const REFUND = 'Refund'

const TransactionHistoryItem: FC<TransactionHistoryListProps> = ({ transaction }) => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const offer: Offer | undefined = useSelector(selectOfferByTransaction(transaction))
  const appStartUTC: string = useSelector(selectStartAppUTC)
  const claimBtnRef = useRef<HTMLButtonElement>(null)
  const [offerRedemption, setOfferRedemption] = useState<Redemption | undefined>()
  const [isRedemptionDetailOpen, setIsRedemptionDetailOpen] = useState(false)
  const [isTermsOpen, setIsTermsOpen] = useState<boolean>(false)
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const { featureFlags }: UserState = useSelector(selectUser)
  useEffect(() => {
    if (transaction.redemption !== undefined) {
      setOfferRedemption(transaction.redemption)
    }
  }, [transaction])

  const handleCloseRedemption = (): void => {
    setIsRedemptionDetailOpen(false)
  }

  const handleOpenHistory = (): void => {
    handleCloseRedemption()
  }

  const isCanceled = (): boolean => {
    if (transaction.earning != null) {
      return transaction.earning.canceled != null && transaction.earning.canceled
    }
    return false
  }

  const showValue = (): any => {
    if (DEDUCTION_TYPES.includes(transaction.transactionType)) {
      return (
        <div className='transaction-history__main__value__txt--negative'>
          -
          <NumberFormat
            value={transaction.redemption.amount}
            displayType='text'
            thousandSeparator
            decimalScale={0}
          />
        </div>
      )
    } else if (transaction.transactionType === TransactionType.Refund) {
      return (
        <div className='transaction-history__main__value__txt'>
          +
          <NumberFormat
            value={transaction.redemption.amount}
            displayType='text'
            thousandSeparator
            decimalScale={0}
          />
        </div>
      )
    } else if (transaction.transactionType === TransactionType.Spend) {
      if (transaction.redemption.amount === 0) {
        return (
          <div className='transaction-history__main__value__txt--free'>
            {t`Free`}
          </div>
        )
      } else {
        let classForRedemption = 'transaction-history__main__value__txt--negative'
        if (transaction.redemption.refundedAtUTC != null) {
          classForRedemption = 'transaction-history__main__value__txt--neglinecross'
        }
        return (
          <div className={classForRedemption}>
            -
            <NumberFormat
              value={transaction.redemption.amount}
              displayType='text'
              thousandSeparator
              decimalScale={0}
            />
          </div>
        )
      }
    } else if (transaction.transactionType === TransactionType.Earn && isCanceled()) {
      return (
        <div className='transaction-history__main__value__txt--linecross'>
          <NumberFormat
            value={transaction.earning.amount}
            displayType='text'
            thousandSeparator
            decimalScale={0}
          />
        </div>
      )
    } else {
      return (
        <div className='transaction-history__main__value__txt'>
          +
          <NumberFormat
            value={transaction.earning.amount}
            displayType='text'
            thousandSeparator
            decimalScale={0}
          />
        </div>
      )
    }
  }

  const isAcknowledge = (): boolean => (
    transaction.transactionType === TransactionType.Earn && (
      transaction.earning.AcknowledgedAtUTC == null || isLatter(appStartUTC, transaction.earning.AcknowledgedAtUTC)
    )
  )

  const handleItemClick = (): void => {
    if (transaction.transactionType === TransactionType.Spend && transaction.redemption.CouponType !== null) {
      if (isCouponActive()) {
        trackEvent({
          action: 'View Redeemed Dialog',
          page: AnalyticsCategory.PTH,
          payload: {
            offerID: transaction.redemption?.OfferID,
            redemptionID: transaction.redemption?.TransactionID
          }
        })
      }
      setIsRedemptionDetailOpen(true)
    } else if (transaction.transactionType === TransactionType.Earn && transaction.earning.ClaimedAtUTC === null) {
      if (claimBtnRef.current != null) {
        claimBtnRef.current.click()
      }
    }
  }

  const isNotDirectExpire = (): boolean => {
    return (
      transaction.redemption.CouponType !== RedemptionType.creditPass &&
      transaction.redemption.CouponType !== RedemptionType.directExpire &&
      transaction.redemption.CouponType !== RedemptionType.directExpireTicket
    )
  }

  const isThisRefunded = (): boolean => {
    if (transaction.redemption != null) {
      if (transaction.redemption.refundedAtUTC != null) {
        return true
      }
    }
    return false
  }

  const isCouponActive = (): boolean => {
    if (isThisRefunded()) {
      return false
    }
    return transaction.transactionType === TransactionType.Spend && transaction.redemption.CouponType !== null && isNotDirectExpire()
  }

  const isPointerActive = (): boolean => {
    return (transaction.transactionType === TransactionType.Earn && transaction.earning.ClaimedAtUTC === null) ||
      isCouponActive()
  }

  const handleOpenTerms = (): void => {
    setIsRedemptionDetailOpen(false)
    setIsTermsOpen(true)
  }

  const getTransactionIcon = (transaction: Transaction): string => {
    if (transaction.transactionIconUrl !== null) {
      return transaction.transactionIconUrl
    }

    if (DEDUCTION_TYPES.includes(transaction.transactionType)) {
      return deductIcon
    }

    if (transaction.redemption != null) {
      return offerIco
    }

    return campaignIco
  }

  const getAdditionalTitle = (): string => {
    if (transaction.transactionType === TransactionType.Refund) {
      return REFUND
    }
    if (isCanceled()) {
      if (transaction.earning.suspensionDeduction) {
        return SUSPENSION
      }
      if (transaction.earning.expiryDeduction) {
        return EXPIRED
      }
      return INACTIVITY
    }
    if (isThisRefunded()) {
      return REFUNDED
    }

    return ''
  }
  const showTransactionTime = (): string => {
    if (!DEDUCTION_TYPES.includes(transaction.transactionType)) {
      return moment
        .default(transaction.transactionDateUTC)
        .local()
        .format('MM/DD/YYYY [at] hh:mm a')
    }
    return ''
  }
  return (
    <div className={`transaction-history ${isPointerActive() ? 'pointer' : ''}`} onClick={handleItemClick}>
      <div className='transaction-history__main'>
        <div className='transaction-history__main__info'>

          {isAcknowledge() && <div className='transaction-history__main__info__dot' />}

          <div className='transaction-history__main__info__circle'>
            <img
              src={getTransactionIcon(transaction)} className='transaction-history__main__info__ico' alt='Transaction History Item Icon'
            />
          </div>

          <div>
            <div className='transaction-history__main__info__title'>{transaction.transactionTitle + ' ' + getAdditionalTitle()}</div>
            <div className='transaction-history__main__info__description'>
              {showTransactionTime()}
            </div>
          </div>
        </div>

        <div className='transaction-history__main__value'>
          {showValue()}
        </div>
      </div>

      {isCouponActive() &&
        <div className='transaction-history__coupon'>
          <div className='default-link'>
            {transaction.redemption.CouponType === RedemptionType.uniqueCode ? t`ViewCoupon` : t`ViewTicket`}
          </div>

          {transaction.redemption.CouponType === RedemptionType.uniqueCode
            ? (
              <BaseDrawer
                isOpen={isRedemptionDetailOpen}
                onClose={handleCloseRedemption}
                isCentered={!featureFlags.UX_UPDATES}
                centerCondition={RedemptionCenterCondition.both}
                noPadding
                isFromUpdatedUI={featureFlags.UX_UPDATES && !matchesMd}
                isFromUpdatedDesktopUI={featureFlags.UX_UPDATES && matchesMd}
                dialogClassName={featureFlags.UX_UPDATES ? 'redemption-drawer-new' : ''}
              >
                <RedemptionWithCode
                  handleCloseRedemption={handleCloseRedemption}
                  offerItem={offer}
                  redemption={offerRedemption}
                  handleOpenHistory={handleOpenHistory}
                  handleOpenTerms={handleOpenTerms}
                  isCustom={matchesMd}
                  isFullWidth
                  isFromUpdatedUI={featureFlags.UX_UPDATES}
                  isFromUpdatedDesktopUI={featureFlags.UX_UPDATES && matchesMd}
                />
              </BaseDrawer>
            )
            : (
              <BaseDrawer
                isOpen={isRedemptionDetailOpen}
                onClose={handleCloseRedemption}
                isCentered={!(featureFlags.UX_UPDATES)}
                centerCondition={RedemptionCenterCondition.both}
                isFromUpdatedUI={featureFlags.UX_UPDATES && !matchesMd}
                isFromUpdatedDesktopUI={featureFlags.UX_UPDATES && matchesMd}
                dialogClassName={featureFlags.UX_UPDATES ? 'redemption-drawer-new' : ''}
              >
                <RedemptionWithTicket
                  handleCloseRedemption={handleCloseRedemption}
                  offerItem={offer}
                  redemption={offerRedemption}
                  isFromUpdatedUI={featureFlags.UX_UPDATES && !matchesMd}
                  isFromUpdatedDesktopUI={featureFlags.UX_UPDATES && matchesMd}
                />
              </BaseDrawer>)}
        </div>}

      {offer != null &&
        <OfferTermsDrawer
          isOpen={isTermsOpen}
          setIsOpen={setIsTermsOpen}
          title={offer.TermsAndConditionsTitle}
          description={offer.TermsAndConditionsBody}
        />}
    </div>
  )
}

export default TransactionHistoryItem
