import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { selectWelcomeScreenConfig } from '../../config/selectors'
import InviteHeader from '../../ui/Invite/InviteHeader/InviteHeader'
import InviteForm from '../../ui/Invite/InviteForm/InviteForm'
import { RoutePath } from '../../core/routes/route-path'
import { changeTab } from '../../config/actions'
import { TabsName } from '../../core/tabsName'

import './InviteCode.scss'
import {
  EMPTY_PROMOTION_CODE_PARAM,
  PROMOTION_CODE_PARAM
} from '../../constants'

const InviteCode: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const welcomeScreenConfig: WelcomeScreenConfig = useSelector(
    selectWelcomeScreenConfig
  )
  const [promoCode, setPromoValue] = useState('')

  const handleInviteCode = async (): Promise<void> => {
    history.push(RoutePath.Home)
    return dispatch(changeTab(TabsName.Earn))
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const promotionCode = params.get(PROMOTION_CODE_PARAM)
    if (
      promotionCode != null &&
      promotionCode !== EMPTY_PROMOTION_CODE_PARAM
    ) {
      setPromoValue(promotionCode)
    }
  }, [location, welcomeScreenConfig])

  return (
    <div className='invite-code'>
      <InviteHeader
        title={welcomeScreenConfig.InviteCodeTitle}
        description={welcomeScreenConfig.InviteCodeDescription}
      />
      <InviteForm
        btnTxt={t`ApplyCode`}
        handleSubmit={handleInviteCode}
        label={t`EnterInviteCode`}
        promotionCode={promoCode}
      />
    </div>
  )
}

export default InviteCode
