/* eslint-disable react/jsx-indent */
import React, { FC } from 'react'
import { Skeleton } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import _ from 'lodash'
import { selectIsActionLoaded, selectConfig } from '../../../config/selectors'
import { GET_CONFIG_INFO } from '../../../config/actionTypes'
import { useTranslation } from 'react-i18next'

import inviteCodeImg from '../../../../assets/images/campaign/referralHeaderImg.png'
import './InviteHeader.scss'

interface InviteCodeHeaderProps {
  title: string
  description?: string
  descriptionMarkdown?: string
  configImg?: string
  isFromTermsPage?: boolean
}

const InviteHeader: FC<InviteCodeHeaderProps> = ({
  title,
  description = '',
  descriptionMarkdown = null,
  configImg = '',
  isFromTermsPage = false
}) => {
  const { t } = useTranslation()
  const config: ConfigState = useSelector(selectConfig)
  const isConfigLoaded = useSelector<boolean>(
    selectIsActionLoaded(GET_CONFIG_INFO)
  )

  function addImage (): any {
    let inviteImage = ''
    if (!_.isNil(configImg) && configImg !== '') {
      inviteImage = configImg
    } else {
      if (!_.isNil(config.WelcomeImage) && config.WelcomeImage !== '') {
        inviteImage = config.WelcomeImage
      } else {
        inviteImage = inviteCodeImg
      }
    }

    if (!_.isNil(inviteImage) && inviteImage !== '') {
      if (inviteImage.length > 0) {
        return (
          <img
            src={inviteImage}
            className='invite-header__img'
            alt='Promo Code'
          />
        )
      }
    }

    return true
  }
  if (_.isNil(title) || title.length === 0) {
    title = t`InviteCodeTitle`
  }
  if (_.isNil(description) || description.length === 0) {
    description = t`inviteCodeInstruction`
  }
  return (
    <div className='invite-header'>
      {addImage()}

      {isConfigLoaded === true ? (
        <div className='invite-header__title'>{title}</div>
      ) : (
        <Skeleton variant='rect' classes={{ root: 'invite-header__title' }} />
      )}

      <div className='invite-header__instruction'>
        {!isFromTermsPage && description}
        {descriptionMarkdown !== null &&
          descriptionMarkdown !== undefined &&
          descriptionMarkdown !== '' && (
          <ReactMarkdown linkTarget='_blank'>
            {descriptionMarkdown}
          </ReactMarkdown>
        )}
      </div>
    </div>
  )
}

export default InviteHeader
